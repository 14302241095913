import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { Container, Div, Row, Col } from '../../common/Elements';
import Text from '../../common/Text/Text';
import { ResourceHubPageData } from '../interfaces/resourcehub-pagedata.interface';
import { ResourceFilter } from './ResourceFilter';
import { ResourceGrid } from './ResourceGrid';
import { Resource } from '../interfaces/resource.interface';
import { On24Entry } from '../../webinars/interfaces/on24.interface';
import { useLocale } from '../../../common/hooks/useLocale';
import { resolveUrl } from '../../../common/util/path';

interface ResourceBrowserProps {
  pageData: ResourceHubPageData;
  englishPageData: ResourceHubPageData;
  resources: Resource[];
  webinars: On24Entry[];
}

export const ResourceBrowser: React.FC<ResourceBrowserProps> = ({
  pageData,
  englishPageData,
  webinars,
  resources,
}) => {
  const { body } = pageData.json_config;

  const location = useLocation();
  const locale = useLocale();
  const filterParams = queryString.parse(location.search);
  const [currentResourceType, setResourceType] = useState('');
  const [currentTopic, setTopic] = useState('');
  const [currentIndustry, setIndustry] = useState('');
  const [currentWebinarLanguage, setCurrentWebinarLanguage] = useState('');
  const { filters } = pageData.json_config;
  const { filters: englishFilters } = englishPageData.json_config;

  useEffect(() => {
    if (filterParams.resourcetype && !Array.isArray(filterParams.resourcetype)) {
      setResourceType(decodeURIComponent(filterParams.resourcetype));
    }

    if (filterParams.topic && !Array.isArray(filterParams.topic)) {
      setTopic(decodeURIComponent(filterParams.topic));
    }

    if (filterParams.industry && !Array.isArray(filterParams.industry)) {
      setIndustry(decodeURIComponent(filterParams.industry));
    }
    if (filterParams.language && !Array.isArray(filterParams.language)) {
      setCurrentWebinarLanguage(decodeURIComponent(filterParams.language));
    }
  }, []);

  useEffect(() => {
    const params = [
      ['topic', currentTopic],
      ['resourcetype', currentResourceType],
      ['industry', currentIndustry],
      ['language', currentWebinarLanguage],
    ].filter(([_, value]) => !!value);

    const search = new URLSearchParams(params);
    window.history.replaceState(
      {},
      document.head.title,
      resolveUrl(locale, `/resource-hub/${params.length > 0 ? '?' : ''}${search.toString()}`)
    );
  }, [currentTopic, currentResourceType, currentIndustry, currentWebinarLanguage]);

  return (
    <Div>
      <Container paddingTop={[4, 4, 4, 9]} paddingBottom={[7, 7, 7, 10]}>
        <Row>
          <Col lg={12}>
            <Text marginBottom={[6, 6, 6, 7]} variant="headline2" tag="H2">
              {body.title}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col marginBottom={5} lg={12}>
            <ResourceFilter
              currentIndustry={currentIndustry}
              currentResourceType={currentResourceType}
              currentTopic={currentTopic}
              currentWebinarLanguage={currentWebinarLanguage}
              setIndustry={setIndustry}
              setResourceType={setResourceType}
              setTopic={setTopic}
              setCurrentWebinarLanguage={setCurrentWebinarLanguage}
              filters={filters}
              englishFilters={englishFilters}
              body={body}
            />
          </Col>
        </Row>
        <ResourceGrid
          currentIndustry={currentIndustry}
          currentResourceType={currentResourceType}
          currentTopic={currentTopic}
          currentWebinarLanguage={currentWebinarLanguage}
          resources={resources}
          webinars={webinars}
          noResultMessage={body.noResultsMessage}
          loadMoreText={body.loadMore}
        />
      </Container>
    </Div>
  );
};
