// Resource hub includes both Contentful data and third-party ON24 API webinars data
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Resource } from './interfaces/resource.interface';
import Layout from '../layout/layout';

import { ResourceHubPageData } from './interfaces/resourcehub-pagedata.interface';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { TopNav } from '../layout';
import { graphql } from 'gatsby';
import { MainFooter } from '../layout/footer/footer.interfaces';
import { On24Entry } from '../webinars/interfaces/on24.interface';
import { ContentfulNavigation } from '../../contentful/content-types/navigation';
import { ResourceBrowser } from './components/ResourceBrowser';
import { HeroBlade } from '../common/refresh-blades/HeroBlade';

export const query = graphql`
  query($locale: String!) {
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }

    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }
    resources: allResource(
      filter: { locale: { eq: $locale }, resourceType: { ne: "Webinar" } }
      sort: { fields: orderDate, order: DESC }
    ) {
      nodes {
        ...resourceFragment
      }
    }
    webinars: allOn24Webinar(
      filter: { goodafter: { ne: null } }
      sort: { fields: goodafter, order: DESC }
    ) {
      nodes {
        id
        title
        url
        description
        thumbnail
        url
        filtergroups {
          name
          filters {
            name
          }
        }
        tags {
          name
        }
        isactive
        displaytimezonecd
        livestart
        goodafter
        liveend
        archivestart
        archiveend
        createtimestamp
      }
    }

    englishPageData: mrkTemplateContentOnly(
      meta_templatePath: { eq: "resource-hub" }
      locale: { eq: "en-US" }
    ) {
      json_config {
        headTags {
          title
        }
        filters {
          industries
          topics
          resourceTypes
        }
        hero {
          title
          body
          streamId
          streamLinkText
        }
        body {
          title
          dropdownHeader1
          dropdownHeader2
          dropdownHeader3
          dropdownAll
          noResultsMessage
          loadMore
        }
      }
    }
    pageData: mrkTemplateContentOnly(
      meta_templatePath: { eq: "resource-hub" }
      locale: { eq: $locale }
    ) {
      json_config {
        headTags {
          title
        }
        filters {
          industries
          topics
          resourceTypes
        }
        hero {
          title
          body
          streamId
          streamLinkText
        }
        body {
          title
          dropdownHeader1
          dropdownHeader2
          dropdownHeader3
          dropdownAll
          noResultsMessage
          loadMore
        }
      }
    }
  }
`;

export interface ResourceHubParams extends GatsbyTemplateProps<AppContextProps> {
  data: {
    resources: {
      nodes: Resource[];
    };
    webinars: {
      nodes: On24Entry[];
    };
    pageData: ResourceHubPageData;
    englishPageData: ResourceHubPageData;
    navData: TopNav;
    redwoodNavData: ContentfulNavigation;
    redwoodRightNavData: ContentfulNavigation;
    utilityNavData: ContentfulNavigation;
    footerData: MainFooter;
    navAbTestData: TopNav;
  };
}

const ResourceHub: FC<ResourceHubParams> = ({ data, pageContext }) => {
  const { navData, navAbTestData, pageData, englishPageData, resources, webinars } = data;
  const { hero, headTags } = pageData.json_config;
  const heroImageUrl = 'https://www.cloudflare.com/img/resource-hub/resource-hub-header.png';

  return (
    <Layout
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      pageContext={pageContext}
      topNavData={navData}
      footerData={data.footerData}
      topNavAbTestData={navAbTestData}
    >
      <Helmet>
        <title>{headTags.title}</title>
      </Helmet>

      <HeroBlade
        page={{
          contentTypeId: 'page',
          pageName: 'resource-hub',
          relativePath: '/resource-hub',
          metaTitle: headTags.title,
          metaDescription: headTags.title,
        }}
        blade={{
          contentTypeId: 'bladeHeroAnimation',
          title: hero.title,
          copy: hero.body,
          button1ExtraClass: '',
          popupStreamId: hero.streamId,
          popupText: hero.streamLinkText,
          image: { file: { publicURL: heroImageUrl } },
          includeVideo: false,
          backgroundColor: 'light-blue',
          leftLinkOptions: 'hide',
          sectionPadding: null,
        }}
      />

      <ResourceBrowser
        pageData={pageData}
        englishPageData={englishPageData}
        resources={resources.nodes}
        webinars={webinars.nodes}
      />
    </Layout>
  );
};

export default ResourceHub;
