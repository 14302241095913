import React, { useState } from 'react';
import { Div, Col, Row, Button, P } from '../../common/Elements';
import { On24Entry } from '../../webinars/interfaces/on24.interface';
import { Resource } from '../interfaces/resource.interface';
import { ResourceCard } from './ResourceCard';
import { StreamModal } from '../../common/StreamModal';
import { removeHiddenResources, parseResources } from '../utils/resource.utils';
import { ButtonCTA } from '../../common/Button';
import { TextVariantMap } from '../../common/Text/Text.interface';

interface ResourceGridProps {
  resources?: Resource[];
  webinars: On24Entry[];
  currentResourceType: string;
  currentTopic: string;
  currentWebinarLanguage: string;
  currentIndustry: string;
  noResultMessage: string;
  loadMoreText: string;
}

export const ResourceGrid: React.FC<ResourceGridProps> = ({
  resources,
  webinars,
  currentWebinarLanguage,
  currentTopic,
  currentResourceType,
  currentIndustry,
  loadMoreText,
  noResultMessage,
}) => {
  const [currentStreamId, setStreamId] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentPage, setPage] = useState(1);
  const webinarsList = webinars;
  const visibleResources = removeHiddenResources(resources || []);
  const parsedResources = parseResources(visibleResources, webinarsList);
  const filteredResources = parsedResources
    .filter(r => !currentTopic || r.topic.includes(currentTopic))
    .filter(r => !currentIndustry || (r.industry && r.industry.includes(currentIndustry)))
    .filter(r => !currentResourceType || r.resourceType === currentResourceType)
    .filter(r => !currentWebinarLanguage || r.language?.includes(currentWebinarLanguage));

  const paginatedResources = filteredResources.slice(0, 12 * currentPage);

  return (
    <>
      <StreamModal
        isOpen={modalIsOpen}
        streamId={currentStreamId}
        closeModal={() => setIsOpen(false)}
      />
      <Row marginBottom={[2, 2, 2, 4]}>
        {paginatedResources.length ? (
          paginatedResources.map(r => {
            return (
              <Col marginBottom={4} key={r.id} lg={4}>
                <ResourceCard
                  resource={r}
                  ctaMethod={streamId => {
                    setStreamId(streamId);
                    setIsOpen(true);
                  }}
                />
              </Col>
            );
          })
        ) : (
          <Col lg={12}>
            <P fontWeight={7} fontSize={5} color="black">
              {noResultMessage}
            </P>
          </Col>
        )}
      </Row>
      <Div display="flex" justifyContent="center">
        {!(filteredResources.length === paginatedResources.length) && (
          <Button
            type="button"
            aria-label="load more"
            backgroundColor="transparent"
            borderColor="blue1"
            border="all"
            color="blue1"
            className="wide-btn pointer"
            hovered={{
              borderColor: 'bluehover',
              color: 'bluehover',
            }}
            focused={{
              outlineWidth: 2,
              outlineStyle: 'solid',
              outlineColor: 'blue1',
              outlineInnerOffset: 2,
              borderColor: 'transparent',
            }}
            borderWidth={2}
            paddingVertical={2}
            {...TextVariantMap.learnmore}
            onClick={() => setPage(currentPage + 1)}
          >
            {loadMoreText}
          </Button>
        )}
      </Div>
    </>
  );
};
