import { graphql, useStaticQuery } from 'gatsby';
import { On24Entry, On24FilterGroup } from '../../components/webinars/interfaces/on24.interface';
import { GlobalStrings } from '../fragments/MRKConfig';
import { useLocale } from './useLocale';

const webinarFilterGroups = graphql`
  query {
    allOn24Webinar {
      nodes {
        id
        filtergroups {
          name
          filters {
            name
          }
        }
      }
    }
  }
`;

export function useWebinarLanguages() {
  const result = useStaticQuery(webinarFilterGroups);
  const nodes: On24Entry[] = result?.allOn24Webinar?.nodes || [];

  const allLanguages = nodes
    .flatMap(node => node.filtergroups)
    .filter(item => item?.name === 'Language')
    .flatMap(filter => filter.filters.map(filter => filter.name))
    .sort();

  return [...new Set(allLanguages)];
}
