import React from 'react';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { useWebinarLanguages } from '../../../common/hooks/useWebinarLanguages';
import { Col, Label, Row, Select } from '../../common/Elements';
import {
  ResourceHubPageBody,
  ResourceHubPageFilters,
} from '../interfaces/resourcehub-pagedata.interface';
import Text from '../../common/Text/Text';
import { TextVariantMap } from '../../common/Text/Text.interface';
import { StyleSystemProps } from '../../common/Elements/Elements';
import { SelectDropdown } from '../../common/SelectDropdown';

interface ResourceFilterProps {
  body: ResourceHubPageBody;
  currentResourceType: string;
  currentTopic: string;
  currentWebinarLanguage: string;
  currentIndustry: string;
  setIndustry: React.Dispatch<React.SetStateAction<string>>;
  setResourceType: React.Dispatch<React.SetStateAction<string>>;
  setTopic: React.Dispatch<React.SetStateAction<string>>;
  setCurrentWebinarLanguage: React.Dispatch<React.SetStateAction<string>>;
  filters?: ResourceHubPageFilters;
  englishFilters?: ResourceHubPageFilters;
}

export const ResourceFilter: React.FC<ResourceFilterProps> = ({
  body,
  filters,
  englishFilters,
  currentIndustry,
  currentResourceType,
  currentTopic,
  currentWebinarLanguage,
  setCurrentWebinarLanguage,
  setIndustry,
  setResourceType,
  setTopic,
}) => {
  const staticStrings = useStaticStrings();
  const languages = useWebinarLanguages();

  const topics = filters?.topics;
  const resourceTypes = filters?.resourceTypes;
  const industries = filters?.industries;
  const englishTopics = englishFilters ? englishFilters.topics : [];
  const englishResourceTypes = englishFilters ? englishFilters.resourceTypes : [];
  const englishIndustries = englishFilters ? englishFilters.industries : [];

  const isWebinar = currentResourceType === 'Webinar';
  const bottomPaddingifWebinar = isWebinar ? 2 : 0;

  const focused: Pick<
    StyleSystemProps,
    'borderColor' | 'outlineColor' | 'outlineStyle' | 'outlineWidth' | 'outlineInnerOffset'
  > = {
    outlineWidth: 2,
    outlineStyle: 'solid',
    outlineColor: 'blue2',
    outlineInnerOffset: 2,
    borderColor: 'transparent',
  };

  return (
    <Row>
      <Col marginBottom={[2, 2, 2, 0]} lg={3}>
        <Label htmlFor="resource-type">
          <Text marginBottom={1} variant="body2-bold">
            {body.dropdownHeader1}
          </Text>
          <SelectDropdown
            name="resource-type"
            id="resource-type"
            value={currentResourceType}
            styleprops={{
              width: '100%',
              paddingVertical: 1,
              ...TextVariantMap.body2,
              focused,
            }}
            onChange={e => {
              const value = (e.target as any).value;
              if (value === 'Webinar') {
                setResourceType(value);
              } else {
                setResourceType(value);
                setCurrentWebinarLanguage('');
              }
            }}
            data-testid="resource-type-dropdown"
          >
            <option value="">{body.dropdownAll}</option>
            {resourceTypes &&
              resourceTypes.map((resource: string, index: number) => (
                <option value={englishResourceTypes[index]} key={resource}>
                  {resource}
                </option>
              ))}
          </SelectDropdown>
        </Label>
      </Col>
      <Col marginBottom={[2, 2, 2, 0]} lg={3}>
        <Label htmlFor="topic">
          <Text marginBottom={1} variant="body2-bold">
            {body.dropdownHeader2}
          </Text>
          <SelectDropdown
            name="topic"
            id="topic"
            value={currentTopic}
            styleprops={{
              width: '100%',
              paddingVertical: 1,
              ...TextVariantMap.body2,
              focused,
            }}
            onChange={e => setTopic((e.target as any).value)}
            data-testid="topic-dropdown"
          >
            <option value="">{body.dropdownAll}</option>
            {topics?.map((r: string, i: any) => (
              <option value={englishTopics[i] as string} key={r}>
                {r}
              </option>
            ))}
          </SelectDropdown>
        </Label>
      </Col>
      <Col
        marginBottom={[bottomPaddingifWebinar, bottomPaddingifWebinar, bottomPaddingifWebinar, 0]}
        lg={3}
      >
        <Label htmlFor="industry">
          <Text marginBottom={1} variant="body2-bold">
            {body.dropdownHeader3}
          </Text>
          <SelectDropdown
            name="industry"
            id="industry"
            value={currentIndustry}
            styleprops={{
              width: '100%',
              paddingVertical: 1,
              ...TextVariantMap.body2,
              focused,
            }}
            onChange={e => setIndustry((e.target as any).value)}
            data-testid="industry-dropdown"
          >
            <option value="">{body.dropdownAll}</option>
            {industries?.map((r: any, i: any) => (
              <option value={englishIndustries[i]} key={r}>
                {r}
              </option>
            ))}
          </SelectDropdown>
        </Label>
      </Col>
      {isWebinar ? (
        <Col lg={3}>
          <Label htmlFor="webinar-language">
            <Text marginBottom={1} variant="body2-bold">
              {staticStrings.json_values.Language}
            </Text>
            <SelectDropdown
              name="webinar-language"
              id="webinar-language"
              value={currentWebinarLanguage}
              styleprops={{
                width: '100%',
                paddingVertical: 1,
                ...TextVariantMap.body2,
                focused,
              }}
              onChange={e => setCurrentWebinarLanguage((e.target as any).value)}
              data-testid="webinar-language-dropdown"
            >
              <option value="">{body.dropdownAll}</option>
              {languages?.map((r: string) => (
                <option value={r} key={r}>
                  {r}
                </option>
              ))}
            </SelectDropdown>
          </Label>
        </Col>
      ) : null}
    </Row>
  );
};
